<template>
  <BaseDialog
    minHeight="200px"
    title="查看详情"
    :dialogVisible="visible"
    :hiddenFooter="true"
    width="1000px"
    @closed="handleClose"
  >
    <div class="search-warpper">
      款式:
      <el-input
        class="input-style"
        clearable
        size="small"
        placeholder="请输入款式"
        v-model="query.productStructName"
        @change="init"
        @clear="init"
      ></el-input>
    </div>
    <div class="flex-between">
      <div class="flex" v-loading="tableLoading">
        <loading-btn class="mr20" size="small" type="primary" @click="onExport">导出</loading-btn>
        <span>总产品数: {{ productCount }}</span>
      </div>
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    </div>
    <CommonTable
      class="common-table"
      height="auto"
      :tableLoading="tableLoading"
      :selection="false"
      :cols="cols"
      :infoData="data"
    >
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </BaseDialog>
</template>

<script>
import { sendDataExport, cutDataExport } from '@/api/exportApi'
import saveAs from 'jszip/vendor/FileSaver.js'
import initDataMixin from '@/mixins/initData'

export default {
  mixins: [initDataMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    detailData: {
      type: Object,
      default: () => {}
    },
    category: {
      type: String,
      default: 'send'
    }
  },
  data() {
    return {
      cols: [
        {
          label: '款式',
          prop: 'productStructName'
        },
        {
          label: '数量 (件)',
          prop: 'productCount'
        }
      ],
      url: '/externaladmin/orderService/orderItem/factoryBatchStyleList',
      xMenuType: 'other',
      productCount: 0,
      mergeData: {
        id: this.detailData.id
      }
    }
  },
  mounted() {
    this.url =
      this.category == 'send'
        ? '/externaladmin/orderService/orderItem/factoryBatchStyleList'
        : '/externaladmin/orderService/orderItem/cutoffBatchStyleList'
  },
  methods: {
    initCallBack(res, data) {
      this.productCount = res.message
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    async onExport() {
      const { id } = this.detailData
      const fun = this.category == 'send' ? sendDataExport : cutDataExport
      const message =
        this.category == 'send' ? `排单批次-${this.detailData.sendTime}` : `截单批次-${this.detailData.cutoffTime}`
      try {
        let res = await fun({ id })
        if (res) {
          saveAs(res, `${message}`)
          this.$message.success('导出成功')
        } else {
          this.$message.warning('无订单数据可导出，请输入正确数值后重试')
        }
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.default-class {
  text-align: right;
}
.common-table {
  margin: 20px 0;
}
.mr20 {
  margin-right: 20px;
}
.search-warpper {
  color: $color-gray;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 20px;
  .input-style {
    width: 240px;
    margin-left: 10px;
  }
}
</style>
