import request from '@/service/request'

//导出排单批次数据
export function sendDataExport(data) {
  return request({
    url: '/externaladmin/orderService/orderItem/exportStyleByFactoryBatch',
    method: 'post',
    responseType: 'blob',
    data
  })
}

//导出截单批次数据
export function cutDataExport(data) {
  return request({
    url: '/externaladmin/orderService/orderItem/exportStyleByCutOffBatch',
    method: 'post',
    responseType: 'blob',
    data
  })
}
