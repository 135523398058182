import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

export function sendToFactoryBatch(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/sendToFactoryBatch/factoryBatchList',
    method: 'post',
    data
  })
}

// 截单批次列表
export function cutOffBatchList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/cutoffBatch/cutOffBatchList',
    method: 'post',
    data
  })
}
