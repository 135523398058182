<template>
  <div class="app-container orderBatch">
    <div v-scrollTop>
      <avue-crud
        ref="crud"
        :option="tableOption"
        :data="tableData"
        :page="tablePage"
        :tableLoading="tableLoading"
        @size-change="sizeChange"
        @current-change="pageChange"
        @search-change="searchChange"
      >
        <template #sendTimeSearchForm="{ form, prop }">
          <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
        </template>
        <template #orderCount="{ row, prop }">
          <span>{{ row.orderCount || 0 }}</span>
          <el-button
            v-p="['externaladmin:orderManage:sendToFactoryBatch:orderNumberDetails']"
            type="text"
            class="ml10"
            @click="viewDetail(row)"
            >查看</el-button
          >
        </template>

        <template #menu="{ row: { id } }">
          <el-button type="text" @click="viewDetails(id)" v-p="['externaladmin:orderManage:sendToFactoryBatch:detail']"
            >查看详情</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <loadingBtn type="text" @click="orderExport(id)" v-p="['externaladmin:orderManage:sendToFactoryBatch:export']"
            >导出</loadingBtn
          >
        </template>
      </avue-crud>
      <viewDetails v-if="dialogVisible" :visible.sync="dialogVisible" :detailData="dialogData"></viewDetails>
    </div>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import { tableOption, detailCol } from './const'
import { ORDER_UN_EXPORTED } from '@/utils/constant'
import { sendToFactoryBatch as getList } from '@/api/order/batch'
import { orderList, exportSchedul } from '@/api/order'
import saveAs from 'jszip/vendor/FileSaver.js'
import SaleDateTime from '@/components/saleDateTime'
import viewDetails from './viewDetails/details'

export default {
  name: 'orderSendToFactoryBatch',
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  components: {
    SaleDateTime,
    viewDetails
  },
  data() {
    return {
      resetMergeData: {
        isReturnRelatedData: 1,
        orderItems: [
          {
            asc: false,
            column: 'send_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ]
      },
      detailCol,
      dialogData: {},
      dialogVisible: false
    }
  },
  created() {},
  methods: {
    async orderExport(id) {
      let listRes = await awaitResolve(
        orderList({
          isReturnRelatedData: 1,
          sendBatchId: id
        })
      )
      if (!listRes || !listRes.detail) return this.$message.warning('导出失败')

      const idList = listRes.detail.map((item) => item.id)

      if (idList.length === 0) {
        this.$message.warning('该生产批次下无订单！')
        return
      }

      let res = await awaitFormResolve(
        exportSchedul({
          idList,
          isCustomsDeclaredExported: ORDER_UN_EXPORTED,
          sendBatchId: id
        })
      )
      if (!res) return this.$message.warning('导出失败')

      saveAs(res, '排单订单')
      this.$message.success('导出成功')
    },
    viewDetail(row) {
      this.dialogData = row
      this.dialogVisible = true
    },
    viewDetails(sendBatchId) {
      this.$router.push({
        path: '/orderManage/sendToFactoryBatch/viewDetails',
        query: {
          sendBatchId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
// .text-small.text-black.el-popover {
//   max-height: 400px;
//   overflow: auto;
// }
</style>
